<template>
  <div id="inici">
    <Menu ref="menu"/>
    <MenuResponsive></MenuResponsive>
    <div class="bg positions-relative">
      <div class="container bg-container">
        <div class="premis-zirkolika">
          <img  src="../assets/logos/zirkotica_orange.svg" style=""/>
        </div>
        <ComingSoon v-if="agenda" :agenda="agenda[0]"></ComingSoon>
      </div>
      <div class="position-absolute logo">
        <img class="mt-4" src="../assets/logos/Logo-cia-nom-provisional-definitiu-Blanc.png"/>
      </div>
    </div>
    <Sinopsi></Sinopsi>
    <Galeria :galeria="galeria1"></Galeria>
    <Fitxa></Fitxa>
    <Video></Video>
    <Companyia></Companyia>
    <Galeria :galeria="galeria2"></Galeria>
    <Agenda :agenda="agenda"></Agenda>
    <Contacte></Contacte>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from '@/components/Menu.vue';
import axios from 'axios';
import ComingSoon from '../components/ComingSoon.vue';
import Sinopsi from '../components/Sinopsi.vue';
import Galeria from '../components/Galeria.vue';
import Fitxa from '../components/Fitxa.vue';
import Video from '../components/Video.vue';
import Companyia from '../components/Companyia.vue';
import Agenda from '../components/Agenda.vue';
import Contacte from '../components/Contacte.vue';
import MenuResponsive from '../components/MenuResponsive.vue';
import Footer from '../components/Footer.vue';

import galeria1 from '../assets/galeria1.json';
import galeria2 from '../assets/galeria2.json';

export default {
  name: 'Home',
  components: {
    Menu,
    ComingSoon,
    Sinopsi,
    Galeria,
    Fitxa,
    Video,
    Companyia,
    Agenda,
    Contacte,
    MenuResponsive,
    Footer,
  },
  data() {
    return {
      galeria1,
      galeria2,
      agendaRaw: [],
      yearsAvaliable: ['2025', '2024', '2023', '2022', '2021', '2020', '2019', '2018'],
    };
  },
  mounted() {
    console.log('%cCia Nom provisional \nDistribució :\ninfo@lamaleta.cat \nCompanyia :\nhola@cianomprovisional.com', 'font-size: 35px; color: #ff6633;background-color:#00338c;padding:1rem;width:100%');
    this.loadEvents();
  },
  computed: {
    agenda() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.agendaRaw.sort((a, b) => b.year - a.year);
    },
  },
  methods: {
    async loadEvents() {
      this.yearsAvaliable.forEach((year) => {
        const date = new Date(year);
        const lastDate = new Date(date.getFullYear(), 12, 31);
        // RFC 3339 format
        const timeMinFormatted = date.toISOString();
        const timeMaxFormatted = lastDate.toISOString();
        axios
          .get(`https://www.googleapis.com/calendar/v3/calendars/i2ckmq329k9ssn9tk4lui0a58g@group.calendar.google.com/events?key=AIzaSyDeXrQwT_IBlixOXpnOqEg8bAbbpNYlT-o&orderBy=startTime&singleEvents=true&timeMin=${timeMinFormatted}&timeMax=${timeMaxFormatted}`)
          // eslint-disable-next-line no-return-assign
          .then((response) => {
            const data = {
              // eslint-disable-next-line radix
              year: parseInt(year),
              data: response.data.items,
            };
            this.agendaRaw.push(data);
            this.test = 2;
          });
      });
    },
  },
};
</script>
